<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card
                        v-if="source"
                        flat
                    >
                        <v-card-text>

                            <v-row v-if="source.ParentType != 'IQalaStatement' && source.Version != null" no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t("Версия") }}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="8">
                                    <div class="onlyReadData">{{ source.Version === 0 ? $t('Текущая') : source.Version }}</div>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t("Имя_файла") }}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="8">
                                    <div class="onlyReadData">
                                        <div class="onlyReadDataEllipsisText" v-tooltip.top-center="source.Name">
                                            {{ source.Name.replace(getExtension(source.Name), '') }}
                                        </div>
                                        <div>{{ getExtension(source.Name) }}</div>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row v-if="source.Description" no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t('Описание') }}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="8">
                                    <div class="onlyReadData">{{ source.Description }}</div>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t('Размер') }}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="8">
                                    <div class="onlyReadData">
                                        {{ parseInt(source.Length) ? `${(parseFloat(source.Length) / (1024 * 1024)).toFixed(2)} ${$t('МБ')}` : '-' }}
                                    </div>
                                </v-col>
                            </v-row>
                            
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t('Дата_создания') }}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="8">
                                    <div class="onlyReadData">{{ source.RecDate }}</div>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "FileInfoDlg",
    data () {
        return {
            title: "Информация_о_файле",
            visible: false,
            resolve: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            source: null
        }
    },
    methods: {
        async open(source)
        {
            this.source = source;
            this.visible = true;
            
            return new Promise((resolve) => {
                this.resolve = resolve;
            });
        },
        cancel() {
            this.visible = false;
            this.resolve();
        },
        getExtension(name) {
            return name.match(/\.([^.]+)$|$/)[0];
        }
    }
}
</script>